import animations from '../../modules/animations'
import { DOM } from '../../modules/settings'

export default class Team {
  constructor() {
    if (!DOM.team) return

    animations.team()
  }
}

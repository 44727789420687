import { DOM } from '../../modules/settings'
import animations from '../../modules/animations'
import Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default class Projects {
  constructor() {
    if (!DOM.projects) return

    animations.projects()
    
    this.setSwiper()
  }

  setSwiper() {
    this.swiper = new Swiper(DOM.projects.querySelector('.swiper'), {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 'auto',
      parallax: true,
    })
  }
}

import { DOM } from '../../modules/settings'
import animations from '../../modules/animations'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Work {
  constructor() {
    if (!DOM.work) return

    animations.work()

    this.setVideo()
  }

  setVideo() {
    const video = DOM.work.querySelector('video')

    if (!video) return

    const play = () => video.play()
    const pause = () => video.pause()

    this.videoSt = ScrollTrigger.create({
      trigger: video.parentNode,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: () => { play() },
      onLeave: () => { pause() },
      onEnterBack: () => { play() },
      onLeaveBack: () => { pause() }
    })
  }

  destroy() {
    this.videoSt && this.videoSt.kill()
  }
}

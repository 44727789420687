import { DOM } from './settings'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(Observer, ScrollToPlugin, ScrollTrigger)

export default class Macaron {
  constructor() {
    if (!DOM.macaron) return

    this.anchor = this.anchor.bind(this)

    DOM.macaron.addEventListener('click', this.anchor)

    this.setMacaron()
  }

  setMacaron() {
    const macaron = DOM.hero.querySelector('.macaron')

    if (!macaron) return

    const baseline = macaron.querySelector('svg g.baseline')
    
    gsap.set(baseline, { transformOrigin: '50% 50%' })

    this.object = {
      value: 1
    }

    const tl = gsap
      .timeline({ 
        defaults: { ease: 'none', duration: 7.5 },
        repeat: -1, 
        onReverseComplete: () => { tl.progress(1) },
      })
      .to(macaron.querySelector('svg g.baseline'), { rotate: 360 })

    Observer.create({
      target: window,
      type: 'wheel,scroll,touch',
      onChangeY: (self) => {
        let v = self.velocityY * 0.0015
        tl.timeScale(v)

        let resting = 1

        if (v < 0) resting = -1
        
        gsap.fromTo(this.object, { 
          value: v 
        }, { 
          value: resting, 
          duration: 1, 
          onUpdate: () => {
            tl.timeScale(this.object.value)
          }
        })
      }
    })

    ScrollTrigger.create({
      trigger: DOM.apply,
      onEnter: () => gsap.to(DOM.macaron, { opacity: 0, duration: 0.5, ease: 'power4.out' }),
      onLeaveBack: () => gsap.to(DOM.macaron, { opacity: 1, duration: 0.5, ease: 'power4.out' })
    })
  }

  anchor(e) { 
    if (!DOM.apply) return

    e && e.preventDefault()

    gsap.to(window, { scrollTo: DOM.apply, duration: 2, ease: 'power4.inOut' })
  }
}
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Scroll {
  constructor() {
    this.lenis = new Lenis({
      smoothWheel: true,
      orientation: 'vertical'
    })

    this.lenis.on('scroll', ScrollTrigger.update)

    // requestAnimationFrame(this.raf.bind(this))
    this.raf()
  }

  raf(time) {
    this.lenis.raf(time)

    requestAnimationFrame(this.raf.bind(this))
  }

  start(){
    this.lenis && this.lenis.start()
  }

  stop() {
    this.lenis && this.lenis.stop()
  }

  destroy() {
    this.lenis && this.lenis.destroy()
  }
}

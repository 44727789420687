import { DOM, settings, partials } from './settings'
import Scroll from './scroll'
import gsap from 'gsap'
import { Flip } from 'gsap/Flip'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(Flip, ScrollTrigger, SplitText)

import Toggle from './toggle'
import Macaron from './macaron'

import Hero from '../templates/partials/hero'
import Projects from '../templates/partials/projects'
import ContentList from '../templates/partials/content-list'
import Team from '../templates/partials/team'
import Work from '../templates/partials/work'
import Apply from '../templates/partials/apply'
import Footer from '../templates/partials/footer'

window.addEventListener('resize', () => ScrollTrigger.update())

// Custom effects
export const titleup = gsap.registerEffect({
  name: 'titleup',
  effect: (targets, config) => {
    return gsap.fromTo(targets[0].lines, {
      yPercent: 100,
      opacity: 0,
    }, { 
      yPercent: 0, 
      opacity: 1,
      stagger: config.stagger, 
      duration: config.duration, 
      ease: config.ease,
      // onComplete: () => {
      //   targets[0].revert()
      // }
    }, 0)
  },
  defaults: { stagger: 0.1, ease: 'expo.out', duration: 1 }, //defaults get applied to any "config" object passed to the effect
  extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
})

// Animations
export default {
  intro: () => {
    if (!DOM.hero) return

    const wokine = DOM.hero.querySelector('.wokine')
    const title = DOM.hero.querySelector('h1')
    const titleSplit = new SplitText(title, { type: 'lines' })
    const infos = DOM.hero.querySelectorAll('.infos li')
    const content = DOM.hero.querySelectorAll('.contents')

    gsap.set(DOM.toggle, { y: 50, opacity: 0 })

    return gsap
      .timeline({
        delay: 1,
        onStart: () => {
          DOM.body.classList.remove('v-h')

          setTimeout(() => window.scrollTo(0, 0), 0)
        },
        onComplete: () => {
          // Remove overlay from the DOM
          DOM.ov.remove()

          // Update body classNames
          DOM.body.classList.remove('loaded')

          // Add settings
          settings.lenis = new Scroll()

          // Add settings
          settings.toggle = new Toggle()
          settings.macaron = new Macaron()
          
          // Add all partials
          // partials.hero = new Hero()
          partials.projects = new Projects()
          partials.missions = new ContentList(DOM.missions)
          partials.profile = new ContentList(DOM.profile)
          partials.team = new Team()
          partials.work = new Work()
          partials.apply = new Apply()
          // partials.footer = new Footer()

          // Update ScrollTriggers
          ScrollTrigger.update()

          // Clean styles
          gsap.set(infos, { clearProps: 'opacity' })
          gsap.set(wokine, { clearProps: 'opacity' })
          gsap.set(DOM.macaron, { clearProps: 'yPercent,opacity' })
          gsap.set(content, { clearProps: 'y,opacity' })
        }
      })
      .titleup(titleSplit, 0)
      .add(() => {
        const state = Flip.getState('h1')
        DOM.body.classList.remove('load')
        DOM.body.classList.add('loaded')
  
        // Clean up
        gsap.set('h1', { clearProps: 'transform' })
  
        // Animate h1 with the className state-loader
        Flip.from(state, { duration: 1.5, ease: 'expo.inOut', scale: true, absolute: true })
      }, 0.75)
      .fromTo(DOM.ov, { scaleY: 1 }, { scaleY: 0, transformOrigin: '100% 100%', ease: 'expo.inOut', duration: 1.5 }, 0.75)
      .from(infos, { opacity: 0, stagger: 0.075, duration: 1, ease: 'power4.inOut' }, 2)
      .from(wokine, { opacity: 0, duration: 0.75, ease: 'power4.inOut' }, 2)
      .from(content, { y: 20, opacity: 0, ease: 'power4.out', duration: 1.25 }, 3.15)
      .from(DOM.macaron, { yPercent: -10, opacity: 0, duration: 1, ease: 'power4.out' }, 2.25)
  },

  // hero: () => {
  //   if (!DOM.hero) return
  // },

  projects: () => {
    const contentsContainer = DOM.projects.querySelector('.content')
    const content = DOM.projects.querySelectorAll('.content')
    const swiper = DOM.projects.querySelector('.swiper')
    const slides = DOM.projects.querySelectorAll('.swiper-slide')

    gsap.from(content, {
      scrollTrigger: {
        trigger: contentsContainer,
        start: 'top 70%',
        end: 'bottom top'
      },
      y: 20,
      opacity: 0,
      ease: 'power4.out', 
      duration: 1.25,
      onComplete: () => {
        gsap.set(content, { clearProps: 'all' })
      }
    })

    gsap.from(slides, {
      scrollTrigger: {
        trigger: swiper,
        start: 'top bottom',
        end: 'bottom top'
      },
      onComplete: () => {
        gsap.set(slides, { clearProps: 'xPercent,opacity' })
      },
      xPercent: 50, 
      opacity: 0,
      stagger: 0.05,
      duration: 1.5,
      ease: 'power4.inOut'
    })
  },

  content_list: (section) => {
    if (!section) return

    const container = section.querySelector('ul')
    const texts = section.querySelectorAll('li .content')
    const lines = section.querySelectorAll('.line')

    gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: 'top 70%',
        end: 'bottom bottom'
      },
      onComplete: () => {
        gsap.set(texts, { clearProps: 'y,opacity' })
        gsap.set(lines, { clearProps: 'all' })
      }
    })
    .from(texts, { y: 20, opacity: 0, ease: 'power4.out', duration: 1.25 }, 0)
    .from(lines, { scaleX: 0, transformOrigin: '0% 0%', ease: 'power4.inOut', duration: 1.5 }, 0)
  },

  team: () => {
    if (!DOM.team) return

    const contentsContainer = DOM.team.querySelector('.contents')
    const content = DOM.team.querySelector('.contents')
    const membersContainer = DOM.team.querySelector('.members')
    const members = DOM.team.querySelectorAll('.team-member')

    gsap.from(content, {
      scrollTrigger: {
        trigger: contentsContainer,
        start: 'top 70%',
        end: 'bottom top'
      },
      y: 20,
      opacity: 0,
      ease: 'power4.out', 
      duration: 1.25,
      onComplete: () => {
        gsap.set(content, { clearProps: 'y,opacity' })
      }
    })

    gsap.from(members, {
      opacity: 0,
      stagger: 0.1,
      duration: 1.5,
      ease: 'power4.out',
      onComplete: () => {
        gsap.set(members, { clearProps: 'opacity' })
      },
      scrollTrigger: {
        trigger: membersContainer,
        start: 'top 75%',
        end: 'bottom top',
      },
    })
  },

  work: () => {
    if (!DOM.work) return

    const video = DOM.work.querySelector('video')
    const contentsContainer = DOM.work.querySelector('.contents')
    const content = DOM.work.querySelectorAll('.contents')
    const talesContainer = DOM.work.querySelector('.tales')
    const tales = DOM.work.querySelectorAll('.work-tale')

    gsap.from(content, {
      scrollTrigger: {
        trigger: contentsContainer,
        start: 'top 70%',
        end: 'bottom top'
      },
      y: 20,
      opacity: 0,
      ease: 'power4.out', 
      duration: 1.25,
      onComplete: () => {
        gsap.set(content, { clearProps: 'y,opacity' })
      }
    })

    gsap.timeline()
      .from(video, {
        scrollTrigger: {
          trigger: DOM.work,
          start: 'top bottom',
          end: 'bottom bottom',
          endTrigger: video.parentNode,
          scrub: true
        },
        yPercent: -100, 
        scale: 0.75, 
        ease: 'none',
      }, 0)
      .from(tales, {
        scrollTrigger: {
          trigger: talesContainer,
          start: 'top 75%',
          end: 'bottom 50%',
          scrub: true
        },
        xPercent: () => gsap.utils.random(-25, 25, 5),
        yPercent: () => 100,
        rotate: () => gsap.utils.random(-5, 5, 1),
        opacity: 0,
        scale: 0,
        stagger: 0.1,
        duration: 1.5,
        ease: 'power4.out'
      }, 0)
  },

  apply: () => {
    if (!DOM.apply) return

    return gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: DOM.apply,
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
      }
    })
    .from(DOM.apply.querySelector('.wrapper'), { yPercent: -40 }, 0)
  },

  footer: () => {
    if (!DOM.footer) return

    return gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: DOM.footer,
        start: 'top bottom',
        end: 'max',
        scrub: true,
      }
    })
    .from(DOM.footer.querySelector('.w'), { yPercent: -40 }, 0)
  }
}

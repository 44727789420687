// Settings
export let settings = {
  lenis: null,
  toggle: null,
  macaron: null
}

// Partials modules
export let partials = {
  hero: null,
  projects: null,
  missions: null,
  profile: null,
  team: null,
  work: null,
  apply: null,
  footer: null
}

// DOM
export let DOM = {
  // General
  body: document.body,
  app: document.getElementById('app'),
  macaron: document.querySelector('.macaron'),
  toggle: document.querySelector('.toggle'),
  ov: document.querySelector('.ov'),
  
  // Sections
  hero: document.querySelector('.hero'),
  projects: document.querySelector('.projects'),
  missions: document.querySelector('.missions'),
  profile: document.querySelector('.profile'),
  team: document.querySelector('.team'),
  work: document.querySelector('.work'),
  apply: document.querySelector('.apply'),
  footer: document.querySelector('footer'),
}

import { DOM } from './settings'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Toggle {
  constructor() {
    if (!DOM.toggle) return

    this.buttons = DOM.toggle.querySelectorAll('button')
    this.mask = DOM.toggle.querySelector('.mask')
    this.contents = DOM.app.querySelectorAll('[data-lang]')

    this.resize()

    window.addEventListener('resize', () => this.resize(), false)

    ScrollTrigger.create({
      trigger: DOM.hero,
      start: '35% top',
      end: 'top bottom',
      endTrigger: DOM.apply,
      onEnter: () => gsap.fromTo(DOM.toggle, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, ease: 'power4.out' }),
      onLeave: () => gsap.to(DOM.toggle, { y: 50, opacity: 0, duration: 0.5, ease: 'power4.out' }),
      onEnterBack: () => gsap.fromTo(DOM.toggle, { y: 50, opacity: 0}, { y: 0, opacity: 1, duration: 0.5, ease: 'power4.out' }),
      onLeaveBack: () => gsap.to(DOM.toggle, { y: 50, opacity: 0, duration: 0.5, ease: 'power4.out' }),
    })
  }

  resize() {
    this.cache = []

    this.buttons.forEach((button, i) => {
      const bounding = button.getBoundingClientRect()
      const params = {
        button: button,
        left: bounding.left - this.mask.getBoundingClientRect().left,
        width: bounding.width
      }

      this.cache.push(params)

      button.addEventListener('click', () => this.update(button, i), false)
    })

    gsap.set(this.mask, { width: this.cache[0].width, x: this.cache[0].left })
  }

  update(button, i){
    this.buttons.forEach(button => button.classList.remove('active'))

    // Udpate styles
    button.classList.add('active')
    gsap.to(this.mask, { width: this.cache[i].width, x: this.cache[i].left, ease: 'power4.inOut', duration: 0.75 })

    // Update contents
    this.contents.forEach(content => {
      const children = content.children

      if (!children && !children.length) return

      [...children].forEach((child, index) => {
        if (i === index) {
          child.removeAttribute('aria-hidden')
        } else {
          child.setAttribute('aria-hidden', 'true')
        }
      })
    })
  }
}
